import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { mediaQueries } from '../../utils/mediaQueries';
import GoogleMapReact from 'google-map-react';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../components/Layout';
import StoreItem from './StoreItem';
import MapMaker from '../../components/MapMaker';
import { setActiveMarker } from '../../state/reducers/map';

const Header = styled.h1`
  margin: 30px 0;
`;

const Container = styled.div`
`;

const StoreContainer = styled.div`
`;

const StoreWrapper = styled.div`
  width: 60%;
  padding: 25px 0;

  ${mediaQueries('md')} {
    width: 100%;
    padding: 25px 0;
  }
`;

const StoreList = styled.div`
  overflow-x: hidden;
  //overflow-y: scroll;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.headerColor};
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;

  &:lang(en) {
    font-weight: bold;
  }
`;

const MapContainer = styled.div`
  //border: 1px solid #000000;
  ${mediaQueries('md')} {
    border: none;
    //height: 400px;
  }
`;

const Selector = styled.select`
  width: 100%;
  background: ${(props) =>
    props.theme.name === 'men' ? `#212121` : `#fafafa`};
  border-radius: 5px;
  padding: 10px 10px;
  option {
    text-align: center;
  }
  color: ${(props) => (props.theme.name === 'men' ? `#ffffff` : `#000000`)};
  font-weight: bold;
`;

const StoreLocator = ({ data }) => {
  const { t } = useTranslation();
  const { isMobileView } = useSelector((state) => state.common);
  const { center, zoom } = useSelector((state) => state.map);
  const dispatch = useDispatch();
  const locations = data.allStoreLocatorJson.nodes;

  return (
    <Layout seoSettings={{ title: t('store_location') }}>
      <Header>{t('store_location')}</Header>
      <Container className="row no-gutters">
        <StoreContainer className="col-12">
            <StoreWrapper>
              {/* <Title>{t('store')}</Title> */}
              <StoreList>
                {locations.map((location, index) => {
                  return (
                    <StoreItem
                      key={location.id}
                      num={index + 1}
                      name={location.name}
                      address={location.address}
                      tel={location.tel}
                      fax={location.fax}
                      openHour={location.openHour}
                      onClick={() =>
                        dispatch(
                          setActiveMarker({
                            num: index + 1,
                            center: { lat: location.lat, lng: location.lng },
                          })
                        )
                      }
                    />
                  );
                })}
              </StoreList>
            </StoreWrapper>
        </StoreContainer>
        {/*<MapContainer className="col-md-8">
           <GoogleMapReact
            onChildClick={() => {
              return null;
            }}
            bootstrapURLKeys={{
              client:
                'gme-lorealsa&channel=LPD_AP_HK_BIO_PR_biotherm.com.hk&v=3',
            }}
            defaultCenter={{
              lat: 22.398016,
              lng: 114.108336,
            }}
            defaultZoom={11}
            center={center}
            zoom={zoom}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              streetViewControl: true,
              fullscreenControl: true,
            }}
          >
            {locations.map((location, index) => {
              return (
                <MapMaker
                  key={index}
                  num={index + 1}
                  info={location}
                  lat={location.lat}
                  lng={location.lng}
                />
              );
            })}
          </GoogleMapReact> 
        </MapContainer>*/}
      </Container>
    </Layout>
  );
};

export default StoreLocator;

export const pageQuery = graphql`
  {
    allStoreLocatorJson {
      nodes {
        id
        name
        address
        tel
        fax
        openHour
        lat
        lng
      }
    }
  }
`;

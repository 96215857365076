import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faClock } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import IconImage from '../../assets/images/storelocator/pin.png';
import IconMenImage from '../../assets/images/storelocator/pin_red.png';

const Container = styled.div`
  //cursor: pointer;
  pointer-events:none;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 10px;
`;

export const Icon = styled.div`
  position: relative;
  color: #ffffff;
  max-width: 15px;
  flex: 0 0 15px;
  height: 18px;
  background: ${(props) =>
    props.theme.name === 'men' ? `url(${IconMenImage})` : `url(${IconImage})`};
  font-size: 11px;

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
`;

const TextWrapper = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.labelColor};

  a{ color : ${(props) => props.theme.labelColor};}
`;

const Name = styled.p`font-size:16px`;
const Address = styled.p``;

const StoreItem = ({ num, name, address, tel, openHour, onClick }) => {
  const { t } = useTranslation();
  return (
    <Container onClick={onClick}>
      <TextWrapper>
        <Name dangerouslySetInnerHTML={{ __html: `${t(name, { lng: 'en' })} / ${t(name, { lng: 'zh' })}` }}></Name>
        <Address>{t(address, { lng: 'en' })} / {t(address, { lng: 'zh' })}</Address>
        <div className='d-flex flex-wrap justify-content-between align-items-center mt-2'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon className='mr-2 max-w-7' icon={faPhone} />
            <p><a href={`tel:${tel}`}>{tel}</a></p>
          </div>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon className='mr-2 max-w-7' icon={faClock} />
            <p>{openHour}</p>
          </div>
        </div>
      </TextWrapper>

    </Container>
  );
};

export default StoreItem;
